@import 'styleguide/bbraun/abstracts/variables';

// colors
$gray-medium-light: #d9d9d9;
$gray-medium-dark: #999999;

// derived colors
$bav-reporting-info-color: $violet;
$bav-reporting-card-border-color: $gray-medium-light;
$changed-state-background-color: $yellow-light;

// typography
$font-size-medium-small: 0.9rem;

// icons
$default-icon-size: 20px;
