@import '~ngx-toastr/toastr';
@import '../abstracts/variables';

@mixin bbraun-toast-theme($theme, $type) {
  background-color: map-get($theme, 'background-color');

  $color: map-get($theme, 'color');

  @at-root .toast-container .ngx-toastr.toast-#{$type},
    .toast-container .ngx-toastr.toast-#{$type}:hover {
    color: $color;
  }

  @at-root .toast-#{$type} .toast-message a,
    .toast-message label {
    color: $color;
  }

  @at-root .toast-#{$type} .toast-message a:hover {
    color: $color;
  }

  @at-root .toast-#{$type} .toast-close-button,
    .toast-#{$type} .toast-close-button:focus,
    .toast-#{$type} .toast-close-button:hover {
    color: $color;
  }
}

.toast-error {
  @include bbraun-toast-theme($notification-theme-error, 'error');
}

.toast-warning {
  @include bbraun-toast-theme($notification-theme-warning, 'warning');
}

.toast-success {
  @include bbraun-toast-theme($notification-theme-success, 'success');
}

.toast-info {
  @include bbraun-toast-theme($notification-theme-info, 'info');
}

.toast-container .ngx-toastr,
.toast-container .ngx-toastr:hover {
  box-shadow: none;
  border-radius: 0;
  background-position: 1em 1em;
  padding: 1em;
}

.toast-title {
  padding-left: 2.2em;
  padding-top: 0.2em; // aligns title with icon
}

.toast-message {
  padding-top: 1em;
}

.toast-info {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z' fill='rgb(113, 30, 130)'/%3E%3C/svg%3E");
}

.toast-error {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z' fill='rgb(179, 25, 31)'/%3E%3C/svg%3E");
}

.toast-success {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' fill='rgb(0, 102, 73)'/%3E%3C/svg%3E");
}

.toast-warning {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z'/%3E%3C/svg%3E");
}

.toast-close-button {
  top: -0.1em;
  right: 0;
  text-shadow: none;
  &:focus,
  &:hover {
    opacity: unset;
  }
}

.ngx-toastr.debug-mode {
  max-height: 500px;
  overflow: auto;
}
