@import './styleguide/bav-reporting/main.scss';
@import './styleguide/bbraun/skeleton';

.bbraun-loading {
  .container {
    .app-name {
      a {
        img {
          height: $toolbar-softwarename-height;
          margin: ($toolbar-height - $toolbar-softwarename-height) / 2 0;
          @include breakpoint(small, max) {
            margin: (
                $toolbar-height-small-screen - $toolbar-softwarename-height
              ) / 2 0;
          }
        }
      }
    }
  }
}
