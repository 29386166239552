@import '../abstracts/variables';

.bbraun-toolbar {
  position: fixed;
  z-index: 1000;
  height: $toolbar-height !important;
  @include breakpoint(small, max) {
    height: $toolbar-height-small-screen !important;
  }
}

.bbraun-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .bbraun-content-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    top: $toolbar-height;
    @include breakpoint(small, max) {
      top: $toolbar-height-small-screen;
    }
    left: 0;
    width: 100%;

    height: calc(100% - #{$toolbar-height});
    @include breakpoint(small, max) {
      height: calc(100% - #{$toolbar-height-small-screen}) !important;
    }

    & > .bbraun-router-container {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;

      & > .bbraun-router-content + *,
      & > router-outlet + * {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        padding: $content-padding-vertical $content-padding-horizontal;
        @include breakpoint(small, max) {
          padding: $content-padding-vertical
            $content-padding-horizontal-small-screen;
        }
        @include breakpoint($breakpoint-larger-app-side-padding, min) {
          padding: $content-padding-vertical
            $content-padding-horizontal-large-screen;
        }
        flex: 1;
      }
    }
  }
}

.bbraun-dashboard-container {
  $dashboard-padding-top: calc(32px - #{$content-padding-vertical});
  $dashboard-horizontal-padding: calc(
    #{$content-padding-horizontal-large-screen} - #{$content-padding-horizontal}
  );
  $dashboard-horizontal-padding-small-screen: calc(
    32px - #{$content-padding-horizontal}
  );
  $dashboard-horizontal-padding-large-screen: 0;

  padding: $dashboard-padding-top $dashboard-horizontal-padding;

  @include breakpoint(small, max) {
    padding: $dashboard-padding-top $dashboard-horizontal-padding-small-screen;
  }
  @include breakpoint($breakpoint-larger-app-side-padding, min) {
    padding: $dashboard-padding-top $dashboard-horizontal-padding-large-screen;
  }
}

.page-title {
  $title-bar-height: calc(#{$toolbar-height} - #{$content-padding-vertical});
  display: block;
  height: $title-bar-height;
  box-sizing: border-box;
  margin: 0;
  // set line height to container size to align baseline of text to bottom padding
  line-height: $title-bar-height;
}

.page-title-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include breakpoint(small, max) {
    margin-bottom: 1rem;
  }

  h1.page-title,
  .action-button-container {
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  h1.page-title {
    margin: 0 !important;
    padding-right: 1rem;
  }

  .action-button-container {
    text-align: end;
    line-height: $content-padding-vertical;
    @include breakpoint(small, max) {
      line-height: $content-padding-vertical-small-screen;
    }
  }
}

.mat-sidenav-container {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;

  .mat-sidenav {
    position: fixed;
    top: $toolbar-height !important;
    @include breakpoint(small, max) {
      top: $toolbar-height-small-screen !important;
    }
    width: 315px;
  }

  .mat-sidenav-content {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
  }
}
