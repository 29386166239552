@import '../abstracts/variables';
@import './material-custom-icon';

.mat-card {
  border-radius: 0 !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3) !important;
}

.mat-toolbar-single-row {
  height: $toolbar-height !important;
  @include breakpoint(small, max) {
    height: $toolbar-height-small-screen !important;
  }
  padding: 0 !important;
}

// disables the cdk-focus class overlay
// see: https://stackoverflow.com/questions/48953972/how-to-disable-or-overwrite-cdk-focused-in-angular-5
.mat-button-focus-overlay {
  background-color: transparent !important;
}

mat-card {
  mat-card-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    align-items: center;

    .mat-card-title.bbraun-subheader {
      @extend .bbraun-subheader;
      margin-bottom: 1rem;
    }

    button[class*='mat-button'] {
      margin-bottom: 1rem;
      margin-left: 1rem;
    }

    .align-right {
      flex-grow: 1;
      text-align: right;
      justify-content: flex-end;
    }

    .reverse-wrap-order {
      display: flex;
      flex-wrap: wrap-reverse;
    }
  }

  &.card-with-grid {
    mat-card-header {
      justify-content: space-between;
      align-content: center;
      margin-bottom: 1rem;
    }

    mat-card-title {
      margin-bottom: 0 !important;
      line-height: 36px !important;
    }

    .mat-card-header-text {
      display: flex;
      align-content: center;
    }
  }
}

mat-dialog-container.mat-dialog-container {
  overflow: initial;
}

div.mat-card-header-text {
  margin: 0;
}

.mat-chip.mat-standard-chip {
  background-color: $gray-light;
  transition: none !important;

  :hover {
    background-color: $gray-light;
  }
}

.mat-chip-focus-overlay {
  background-color: transparent;
}

.mat-drawer-container {
  background-color: white;
}

// style-guide: "big headline" in toolbar
.mat-toolbar {
  h1.bbraun-header {
    font-family: Arial bold, $font-family;
    font-size: $font-size-headline;
    font-weight: bold;
  }
}

// bbraun-buttons
// https://www.bbraun-brand.com/bbraun-brand-guide/en/design/digital/apps1/interaction-elements1.html#button
.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  border-radius: unset !important;
  font-weight: bold;

  // bbraun primary button
  &[color='primary'] {
    background-color: $primary-button-background-color;
    color: $primary-button-text-color;
    box-shadow: $primary-button-box-shadow;

    &:hover {
      background-color: $primary-button-background-color-hover;
    }

    &[disabled] {
      background-color: $primary-button-background-color-disabled;
      color: $primary-button-text-color;
    }
  }

  // bbraun secondary button
  &:not([color='accent']):not([color='primary']) {
    box-shadow: inset 0 0 0 2px $secondary-button-border-color;
    background-color: $secondary-button-background-color;
    color: $secondary-button-border-color;

    &.area-button {
      &:hover {
        color: $black;
        background-color: $area-button-hover-color;
      }
    }

    &:hover {
      background-color: $secondary-button-background-color-hover;
      color: $secondary-button-color-hover;
    }

    &:disabled {
      box-shadow: inset 0 0 0 2px $secondary-button-border-color-disabled;
      color: $secondary-button-border-color-disabled;
    }
  }

  // bbraun action button
  &[color='accent'] {
    background-color: $action-button-background-color;
    color: white;
    box-shadow: none;

    &:hover {
      background-color: $action-button-background-color-hover;
    }

    &:disabled {
      background-color: $action-button-background-color-disabled;
      color: white;
    }
  }
}

.mat-button[disabled],
.mat-raised-button[disabled],
.mat-stroked-button[disabled],
.mat-flat-button[disabled] {
  pointer-events: none;

  &:not([color='accent']):not([color='primary']):disabled {
    .mat-progress-spinner,
    .mat-spinner {
      circle {
        stroke: $secondary-button-border-color-disabled;
      }
    }
  }
  .mat-progress-spinner,
  .mat-spinner {
    circle {
      stroke: white;
    }
  }
}

mat-sidenav {
  .mat-drawer-inner-container {
    .mat-list-base {
      padding-top: 0;

      .mat-list-item {
        font-size: $font-size;
        color: $gray-dark;
        height: $sidebar-menu-item-height;

        &:hover {
          background-color: $gray-light;
          color: $green;
        }

        .mat-list-item-content {
          padding: 0 20px;

          .mat-icon {
            $margin: ($sidebar-menu-item-height - $sidebar-menu-icon-size) / 2;
            width: $sidebar-menu-icon-size;
            height: $sidebar-menu-icon-size;
            line-height: $sidebar-menu-icon-size;
            text-align: center;
            margin: 12px 0;
            font-size: $sidebar-menu-icon-size;
          }

          a {
            height: $sidebar-menu-item-height;
            line-height: $sidebar-menu-item-height;
            margin-left: 20px;
            flex: 1;
          }
        }
      }
    }
  }
}

mat-card {
  &.bbraun-flat {
    box-shadow: none !important;
    border: 2px solid $gray-light;
    &.has-error {
      border-color: $invalid-color;
    }
    &.has-warning {
      border-color: $warning-color;
    }
    &.has-info {
      border-color: $violet;
    }
  }

  &.bbraun-no-border {
    box-shadow: none !important;
    border: none !important;
  }
}

.cdk-fokus-trap-anchor {
  display: none !important;
}

// these following rules are very important!

// normally, mat-sidenav-content is the scrollable container and the backdrop only fills the parent mat-sidenav-container.
// because we want to scroll the window (needed for scrollspy and anchor routing), we fixed mat-sidenav and forced
// mat-sidenav-content to overflow its parents (bubbling up to window).
// in views exceeding the browser height, a relative positioned backdrop would only fill the visible window (no backdrop on initially
// invisible areas). with fixed position, the element is moved out of the normal flow of elements and width/height refer to width/height
// of window, not of the direct parent

.mat-drawer-container {
  overflow: unset !important;
  .mat-drawer-content {
    overflow: unset !important;
  }
}

.mat-drawer-backdrop {
  position: fixed !important;
  width: 100%;
  height: 100%;
}

// end scrolling & backdrop rules

.page-title {
  .mat-chip-list-wrapper {
    display: inline-flex;
    margin: 0;
    vertical-align: text-bottom;
    .mat-chip {
      margin: 0;
    }
  }
}

mat-chip.status-chip {
  opacity: 1 !important;
  padding: 0 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  min-height: 26px;
}

.bbraun-dialog {
  .mat-dialog-container {
    border-radius: 0;
    padding: 12px;
  }

  .title-container {
    flex: 0 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 1rem;

    .title {
      padding-top: 0.5rem;
      font-size: $font-size-headline;
    }

    .close-button {
      text-align: right;
      height: $icon-button-size;
      width: $icon-button-size;
      line-height: $icon-button-size;
    }
  }

  .body-text-container {
    text-align: justify;
  }
}

mat-progress-bar.bbraun-progress-bar {
  height: $progress-bar-height;

  svg.mat-progress-bar-background {
    fill: $progress-bar-inactive-color;
  }
  .mat-progress-bar-buffer {
    background-color: $progress-bar-inactive-color;
  }
  .mat-progress-bar-fill::after {
    background-color: $progress-bar-active-color;
  }

  &.hidden {
    visibility: hidden;
  }
}
