.mat-icon[hidden] {
  display: none;
}

mat-icon {
  &.full-size {
    height: 100%;
    width: 100%;
  }

  &.bbraun-info-icon {
    color: $violet;
    font-size: $info-icon-font-size;
    cursor: pointer;
  }
}

.mat-icon-button.bbraun-mat-icon-button {
  height: unset;
  width: unset;
  line-height: unset;
  margin-right: 0.5rem;

  color: $icon-button-color;

  &:hover:not(.disabled) {
    color: $icon-button-hover-color;
  }

  .mat-icon {
    height: $icon-button-size;
    width: $icon-button-size;
  }
}

.bbraun-button-small-icon-only {
  box-shadow: inset 0 0 0 2px $secondary-button-border-color;
  background-color: white;
  color: $gray-dark;
  border: 0;
  line-height: 1em;
  height: 30px;
  width: 30px;

  .mat-icon {
    width: unset !important;
  }
}

.bbraun-close-icon-container,
.bbraun-delete-icon-container {
  position: absolute;
  top: 10px;
  right: 15px;

  mat-icon.bbraun-close-icon,
  mat-icon.bbraun-delete-icon {
    cursor: pointer;
    height: 18px;
    width: 18px;

    mat-icon {
      width: unset;
    }
  }

  mat-icon.bbraun-close-icon {
    color: $gray-mid;
  }
}
