// colors
$green: #00b482;
$green-pressed: #006649;
$white: #ffffff;
$gray-light: #f2f2f2;
$gray-dark: #646464;
$gray-dark-hover: #595959;
$gray-dark-inactive: #c8c8c8;
$gray-header: #e5e5e5;
$gray-mid: #aeaeae;
$black: #333333;
$violet: #711e82;
$violet-dark: #4f1d59;
$violet-light: #ecdff0;
$violet-inactive: #b78fbf;
$red: #dc1f26;
$red-dark: #b3191f;
$red-light: #f8d2d4;
$yellow: #ffbf00;
$yellow-light: #fff6dd;

// status
$neutral-color: $gray-mid;
$success-color: $green;
$invalid-color: $red;
$warning-color: $yellow;
$highlight-color: $green;

// buttons
$standard-button-height: 2.5rem;

$standard-button-group-small-margin: 1px;

$primary-button-background-color: $gray-dark;
$primary-button-background-color-hover: $gray-dark-hover;
$primary-button-background-color-disabled: $gray-mid;
$primary-button-text-color: $white;
$primary-button-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

$secondary-button-background-color: $white;
$secondary-button-border-color: $gray-dark;
$secondary-button-background-color-hover: $secondary-button-border-color;
$secondary-button-color-hover: $white;
$secondary-button-border-color-disabled: $gray-mid;

$action-button-background-color: $violet;
$action-button-background-color-hover: $violet-dark;
$action-button-background-color-disabled: $violet-inactive;
$action-button-text-color: $white;

$highlighted-button-background-color: $highlight-color;
$highlighted-button-border-color: $highlight-color;
$highlighted-button-text-color: $white;

$alert-button-color: $invalid-color;

$area-button-hover-color: #d9d9d9;

$meta-data-text-color: $gray-dark;

// notifications (messages/toasts and validation)
$notification-error-text-color: $red-dark;
$notification-error-background-color: $red-light;

$notification-theme-error: (
  'color': $notification-error-text-color,
  'background-color': $notification-error-background-color,
);

$notification-warning-text-color: #000000;
$notification-warning-background-color: #ffe189;

$notification-theme-warning: (
  'color': $notification-warning-text-color,
  'background-color': $notification-warning-background-color,
);

$notification-success-text-color: $green-pressed;
$notification-success-background-color: #b2e8d9;

$notification-theme-success: (
  'color': $notification-success-text-color,
  'background-color': $notification-success-background-color,
);

$notification-info-text-color: $violet;
$notification-info-background-color: #e2d3e9;

$notification-theme-info: (
  'color': $notification-info-text-color,
  'background-color': $notification-info-background-color,
);

$success-text-color: $success-color;

// warning background color for background outside of notifications
$warning-background-color: $yellow-light;

// general
$default-margin: 1rem;
$default-padding: 1rem;
$default-gap-size: 1rem;

// icon
$info-icon-font-size: 20px;
$icon-button-size: 1.5rem;
$icon-left-button-left-padding: 8px;
$icon-button-color: $gray-dark;
$icon-button-hover-color: $gray-dark-hover;

$arrow-icon-inherent-margin: 4px;

// opacity
$opacity-light: 0.3;
$opacity-medium: 0.6;
$opacity-heavy: 0.8;
$overlay-brightness: 90%;

// typography
$font-family: Arial, sans-serif;
$font-size-headline: 1.4rem;
$font-size-subheadline: 1.2rem;
$font-size: 1rem;
$font-size-small: 0.8rem;

// grid
$gutter-width: 32px;

// toolbar
$toolbar-height: 72px;
$toolbar-height-small-screen: 36px;
$toolbar-softwarename-height: 20px;
$toolbar-softwarename-horizontal-margin: $gutter-width;
$toolbar-logo-height: 20px;
$toolbar-menu-icon-size: 32px;
$toolbar-vertical-border-width: 1px;

// progress bar
$progress-bar-height: 4px;
$progress-bar-inactive-color: $gray-light;
$progress-bar-active-color: $violet;

// sidebar
$sidebar-menu-item-height: 56px;
$sidebar-menu-horizontal-spacing: 20px;
$sidebar-menu-vertical-spacing: 16px;
$sidebar-menu-icon-size: $toolbar-logo-height; // adjust to menu icon

// dialog window
$dialog-title-height: 3rem;

// TODO: consistent naming of variables (remain until configurator is merged)
$app-navbar-height: 72px;

$content-padding-vertical: 16px;
$content-padding-vertical-small-screen: 16px;

$content-padding-horizontal: 16px;
$content-padding-horizontal-small-screen: 16px;
$content-padding-horizontal-large-screen: $toolbar-height;

$load-indicator-width: 1rem;
$load-indicator-height: 1rem;
