@import '../abstracts/variables';
@import '../abstracts/mixins';

$directions: (
  'l': 'left',
  'r': 'right',
  't': 'top',
  'b': 'bottom',
);

$lengths: (
  0rem: '0',
  0.5rem: '0-5',
  1rem: '1',
  1.5rem: '1-5',
  2rem: '2',
  4rem: '4',
);

.fill-remaining-space {
  flex: 1 1 auto;
}

@each $directionShort, $direction in $directions {
  @each $length, $appendix in $lengths {
    .m#{$directionShort}-#{$appendix} {
      margin-#{$direction}: $length !important;
    }
    .p#{$directionShort}-#{$appendix} {
      padding-#{$direction}: $length !important;
    }
  }
}

@each $length, $appendix in $lengths {
  .m-#{$appendix} {
    margin: $length !important;
  }
  .p-#{$appendix} {
    padding: $length !important;
  }
  .h-#{$appendix} {
    height: $length !important;
  }
  .w-#{$appendix} {
    width: $length !important;
  }
  .gap-#{$appendix} {
    gap: $length !important;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.fl-r {
  float: right;
}

.fl-l {
  float: left;
}

.width-3 {
  width: 3rem;
}

.half-width {
  width: 50%;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.full-size {
  width: 100% !important;
  height: 100% !important;
}

.min-width-100 {
  min-width: 100%;
}

// color for clickable elements
.bbraun-clickable,
a {
  color: $gray-dark;

  &:hover {
    color: $gray-dark-hover;
  }
}

.bbraun-clickable.disabled,
a.disabled {
  color: $gray-dark-inactive;
  cursor: default;
}

.bbraun-clickable-cursor {
  cursor: pointer;
}

.flex-box {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }

  &.full-size {
    width: 100%;
    height: 100%;
  }

  .flex-item {
    flex: 1 1 auto;

    &.no-flex-grow {
      flex: 0 1 auto;
    }

    &.no-flex-shrink {
      flex: 1 0 auto;
    }

    &.no-flex {
      flex: 0 0 auto;
    }
  }

  .flex-item-fix {
    flex: 1 1 0;
  }

  &.align-items-center {
    align-items: center;
  }

  &.align-items-flex-end {
    align-items: flex-end;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-flex-end {
    justify-content: flex-end;
  }

  &.justify-content-flex-start {
    justify-content: flex-start;
  }

  &.justify-content-space-evenly {
    justify-content: space-evenly;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  &.flex-grow-1 {
    flex-grow: 1;
  }

  &.flex-column {
    flex-direction: column;
  }

  @each $orientationShort, $orientation in ('col': 'column', 'row': 'row') {
    @each $length, $appendix in $lengths {
      &.#{$orientationShort}-gap-#{$appendix} {
        #{$orientation}-gap: $length;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.word-wrap-none {
  white-space: nowrap;
}

.word-wrap-normal {
  white-space: normal;
}

.cut-text-overflow {
  @include cut-text-overflow();
}

.invisible {
  visibility: hidden;
}

.vertical-align-middle {
  vertical-align: middle;
}

.highlighted {
  color: $highlight-color !important;
}
