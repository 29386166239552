@import '../abstracts/variables';

body {
  font-family: $font-family;
  color: $black;
  font-size: $font-size;
}

.bbraun-subheader {
  font-size: $font-size-subheadline;
  font-weight: bold;
}

// this style does not appear in the style guide and was added individually
.bbraun-minor-subheader {
  font-size: $font-size;
  font-weight: bold;
}

h1 {
  font-size: $font-size-headline;
  font-weight: bold;
}

h2 {
  font-size: $font-size-subheadline;
  font-weight: normal;
}
