@import './styleguide/bbraun/abstracts/_variables';

.bbraun-loading {
  width: 100%;
  height: 100%;

  .container {
    width: 100%;
    height: $toolbar-height;
    @include breakpoint(small, max) {
      height: $toolbar-height-small-screen;
    }
    overflow: hidden;
    display: flex;
    flex-direction: row;

    .toggle-sidebar-placeholder,
    .backbutton-placeholder {
      flex: 0 0 auto;
      background-color: transparent;
      width: $toolbar-height;
      height: $toolbar-height;
      @include breakpoint(small, max) {
        width: $toolbar-height-small-screen;
        height: $toolbar-height-small-screen;
      }
      margin: 0;
      border: 0;
      outline: 0 none;
    }

    .toggle-sidebar-placeholder {
      border-right: $toolbar-vertical-border-width solid white;
    }

    .app-name {
      flex: 1 1 auto;

      a {
        display: block;
        height: $toolbar-height;
        @include breakpoint(small, max) {
          height: $toolbar-height-small-screen;
        }
        margin: 0 0 0 1rem;
        $software-name-full-height: 36px;
        img {
          height: $software-name-full-height;
          margin: ($toolbar-height - $software-name-full-height) / 2 0;
          @include breakpoint(small, max) {
            margin: ($toolbar-height-small-screen - $software-name-full-height) /
              2 0;
          }
        }
      }
    }
  }

  .bbraun-container {
    position: absolute;
    left: 0;
    top: 0;

    .content {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: $content-padding-vertical $content-padding-horizontal;
      flex: 1;

      .loadingindicator-circle {
        width: 32px;
        height: 32px;
        z-index: 1501;
        position: absolute;
        left: 50%;
        top: calc(50% - (#{$toolbar-height}/ 2));
        @include breakpoint(small, max) {
          top: calc(50% - (#{$toolbar-height-small-screen}/ 2));
        }
        transform: translate(-50%, -50%);
        transition: all 0s ease 0s;
      }
    }
  }
}
