@import 'breakpoints';

@mixin grid-column-span($span, $offset: 0) {
  grid-column-end: span $span;
}

@mixin grid-column-offset($offset) {
  grid-column-start: $offset + 1;
}

@mixin grid-row-span($span, $offset: 0) {
  grid-row-end: span $span;
}

@mixin grid-row-offset($offset) {
  grid-row-start: $offset + 1;
}

@mixin bbraun-grid($columns: 12, $gap: 32px) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  @include breakpoint(small, min) {
    grid-column-gap: $gap;
  }
  grid-row-gap: $gap;
  align-items: stretch;
  justify-items: stretch;
}

@mixin cut-text-overflow($white-space: nowrap) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: $white-space;
}

$border-width: (
  'small': 1px,
  'medium': 2px,
);

@mixin border-configuration($border-colors) {
  @each $borderWidthName, $borderWidth in $border-width {
    @each $borderColorName, $borderColor in $border-colors {
      .#{$borderWidthName}-border-#{$borderColorName} {
        border: $borderWidth solid $borderColor !important;
      }

      .#{$borderWidthName}-outline-#{$borderColorName} {
        outline: $borderWidth solid $borderColor !important;
      }
    }
  }
}
