@import '../abstracts/variables';
@import 'styleguide/bbraun/abstracts/variables';

mat-card.bbraun-flat {
  color: $black;
  border: 2px solid $bav-reporting-card-border-color;
}

mat-accordion.bbraun-flat {
  mat-expansion-panel.mat-expansion-panel {
    $header-section-min-height: 2.5rem;

    color: $black;
    border: 2px solid $bav-reporting-card-border-color;
    box-shadow: none;
    border-radius: 0 !important;
    margin: 0;

    mat-expansion-panel-header,
    mat-expansion-panel-header:hover,
    mat-expansion-panel-header:focus {
      background-color: $white;
      height: auto !important;
      min-height: $header-section-min-height !important;
      padding: 0.3rem 1rem 0;

      .mat-expansion-indicator::after {
        margin-bottom: 0.4rem;
      }

      &.mat-expanded {
        border-bottom: 1px solid $gray-medium-light;
        background-color: $gray-light;
      }
    }
  }
  &.table-content {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}
