@import './../abstracts/mixins';

.bbraun-grid {
  $columns: 12;

  @include bbraun-grid($columns);
  @for $i from 1 to $columns + 1 {
    .c-span-#{$i} {
      @include grid-column-span($i);
    }
    .c-offset-#{$i} {
      @include grid-column-offset($i);
    }
  }
  @include breakpoint('medium', min) {
    @for $i from 1 to $columns + 1 {
      .c-span-#{$i}-md {
        @include grid-column-span($i);
      }
      .c-offset-#{$i}-md {
        @include grid-column-offset($i);
      }
    }
  }
  @include breakpoint('large', min) {
    @for $i from 1 to $columns + 1 {
      .c-span-#{$i}-lg {
        @include grid-column-span($i);
      }
      .c-offset-#{$i}-lg {
        @include grid-column-offset($i);
      }
    }
  }
  @include breakpoint('xlarge', min) {
    @for $i from 1 to $columns + 1 {
      .c-span-#{$i}-xl {
        @include grid-column-span($i);
      }
      .c-offset-#{$i}-xl {
        @include grid-column-offset($i);
      }
    }
  }
}

.bbraun-button-grid {
  $columns: 5;
  $gap: 8px;

  @include bbraun-grid($columns, $gap);

  @for $i from 1 to $columns + 1 {
    .c-span-#{$i} {
      @include grid-column-span($i);
      padding: 8px 0;
      button {
        height: 56px;
        width: 100%;
      }
    }
    .c-offset-#{$i} {
      @include grid-column-offset($i);
    }
  }
}
