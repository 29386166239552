@import '../abstracts/variables';
@import '../abstracts/breakpoints';

$dx-textarea-height-medium: 140px;
$dx-input-field-height: 38.5px;
$dx-texteditor-padding-left: 10px;

$dx-icon-button-background-color: #f5f5f5; // taken from _devexpress_theme
$dx-icon-button-text-color: #a8a8a8; // taken from _devexpress_theme

.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 10px 10px !important;
}

dx-lookup.dx-editor-filled .dx-lookup-field,
.dx-lookup.dx-editor-outlined .dx-lookup-field {
  padding: 10px 10px !important;
}

.dx-searchbox.dx-editor-filled .dx-icon-search,
.dx-searchbox.dx-editor-outlined .dx-icon-search {
  left: 10px;
}

.dx-searchbox.dx-editor-filled .dx-texteditor-input,
.dx-searchbox.dx-editor-outlined .dx-texteditor-input,
.dx-searchbox.dx-editor-filled .dx-placeholder:before,
.dx-searchbox.dx-editor-outlined .dx-placeholder:before {
  padding-left: 32px !important;
}

.dx-placeholder {
  top: -10px !important;
}

.dx-field-item {
  font-size: 14px !important;
}

.dx-item .dip-dxi-item-disabled {
  opacity: 0.38;
}

dx-data-grid.bbraun-align-all-column-headers-center .dx-header-row > td {
  text-align: center !important;
}

// shows filtericon in filter row of data grid when operationChooser is disabled.
.dx-datagrid-content .dx-datagrid-table .dx-row td.dx-editor-cell {
  input.dx-texteditor-input {
    padding-left: 1.2em !important;
  }

  &:before {
    content: '\f027';
    top: 30px;
    left: -5px;
    position: relative;
    font-family: DXIcons;
    color: $gray-dark;
    font-size: large;
  }
}

.dx-row.dx-header-row,
.dx-datagrid-headers .dx-datagrid-table .dx-row,
.dx-datagrid-filter-row {
  background-color: white !important;

  > td.dx-datagrid-action:hover,
  > td.dx-editor-cell:hover {
    background-color: $gray-light !important;
  }
}

.dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td,
.dx-datagrid-rowsview .dx-row:hover > td {
  background-color: $gray-light;
}

dx-form[ng-reflect-read-only='true'] span.dx-field-item-required-mark {
  color: transparent !important;
}

.dx-state-readonly .dx-dropdowneditor-icon {
  color: transparent;
}

.dx-form-group-with-caption .dx-form-group-content {
  padding: 0;
  padding-top: 0.5rem;

  .dx-field-item {
    padding-bottom: 0;
  }
}

.dx-field-item-label-location-top {
  padding: 3px 0 3px;
}

.dx-tab a {
  background-color: inherit;
  color: $gray-dark;
  text-decoration: unset;
  position: initial;
  opacity: 1;
}

// custom dx styling for dx-tabs
.hide-scroll-button-right .dx-tabs-nav-button-right i {
  color: transparent !important;
}

.bbraun-navigation-bar {
  .dx-tabs {
    background-color: white;

    .dx-tab {
      background-color: white;
      padding: 0.5rem 0 0 0 !important;

      a {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }

      &.dx-tab-selected {
        a {
          color: $highlight-color;
        }
      }
    }

    .dx-tab-content {
      text-transform: none;
      width: 100%;
      height: 100%;
    }

    .dx-tabs-nav-button {
      background-color: $white;
      height: 100%;

      .dx-button-content {
        background-color: $white;
      }
    }
  }
}

.dx-slider-handle {
  z-index: 1;

  .dx-tooltip-wrapper .dx-overlay-content {
    &:before {
      background-color: transparent;
    }

    .dx-popup-content {
      padding: 0;
      top: 1.15rem;
      color: $green;
    }
  }
}

.dx-slider-tooltip-position-top {
  padding-top: 0.3rem !important;
}

.dx-slider-wrapper {
  height: 1.1rem;
}

.bbraun-form-checkbox .dx-checkbox-container {
  padding: 0.5rem;
}

.no-label-padding .dx-field-item-label-location-top {
  padding: 0;
}

.dx-item.dx-state-disabled {
  .dx-item-content {
    .dx-field-item-label {
      .dx-field-item-label-content {
        opacity: 0.38;
      }
    }
  }
}

.bbraun-load-indicator {
  width: $load-indicator-width;
  height: $load-indicator-height;

  &.white {
    .dx-loadindicator-segment .dx-loadindicator-segment-inner {
      border-color: $white;
    }
  }

  &.gray {
    .dx-loadindicator-segment .dx-loadindicator-segment-inner {
      border-color: $gray-mid;
    }
  }

  &.transparent {
    .dx-loadindicator-segment .dx-loadindicator-segment-inner {
      border-color: transparent;
    }
  }

  .dx-loadindicator-segment {
    &.dx-loadindicator-segment0 .dx-loadindicator-segment-inner {
      border-left-color: transparent;
      border-bottom-color: transparent;
    }

    &.dx-loadindicator-segment1 .dx-loadindicator-segment-inner {
      border-bottom-color: transparent;
    }

    &.dx-loadindicator-segment2 .dx-loadindicator-segment-inner {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }
}

.dx-selectbox .dx-loadindicator {
  width: $load-indicator-width;
  height: $load-indicator-height;
  vertical-align: middle;
}

.dx-box-item .checkbox-position-left.dx-field-item > .dx-field-item-content {
  flex-grow: 0;
  width: 1.5rem;
}

// this is used for radio groups that are displayed similar to option cards in the configurator
.dx-radio-group-field {
  $content-padding: 1rem;

  width: 100%;

  .dx-field-value {
    width: 100% !important;

    dx-radio-group {
      margin-top: 0 !important;
    }

    .configurator-option-radio-group-card {
      &.col-2 .dx-widget.dx-collection {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 0.5rem;
        // 40px is twice the side padding of devexpress items
        grid-column-gap: 40px;
      }

      &.col-3 .dx-widget.dx-collection {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $content-padding;
      }

      &.mt-1 .dx-item.dx-radiobutton {
        margin-top: 1rem;
      }

      .dx-item.dx-radiobutton {
        background-color: $gray-light;
        margin-right: 0;
        padding: $content-padding;
        display: flex;
        height: 100%;

        @include breakpoint(large, max) {
          grid-column: span 3;
        }

        .dx-template-wrapper.dx-item-content {
          width: 100%;
        }

        &.dx-item-selected {
          outline: 2px solid $green;
        }

        .option-title {
          font-size: $font-size;
          color: $violet;
        }

        .description {
          margin-top: calc(0.5 * #{$content-padding});

          // this offsets exactly the width of the dx radiobutton and its padding
          margin-left: -30px;
        }
      }
    }
  }
}

.title-only-group {
  padding-bottom: 0;
}

// this is for radio groups that are displayed on cards, where the group is split into different sections
.splitRadioGroupContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 40px;

  .grid-element {
    @include breakpoint(medium, max) {
      grid-column: span 2;
    }
  }
}

div.full-item-height {
  height: 100%;

  .dx-field-item-content {
    height: 100%;

    dxi-item.dx-template-wrapper {
      height: 100%;

      .dx-field-item-content {
        height: 100%;

        div.dx-field-item.full-item-height {
          height: 100%;
        }
      }
    }
  }
}

.dx-list-item {
  &:first-of-type {
    margin-top: 0;
  }

  .dx-button-content {
    padding: 0;
  }

  .dx-list-item-content {
    padding: 0.5em;
  }
}

// no color change on hover on disabled filter cells
.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row
  > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space) {
  background-color: initial;
}

.dx-widget,
.dx-widget input,
.dx-field-item-label-text,
.dx-datagrid-headers,
.dx-texteditor,
.dx-texteditor textarea,
.dx-texteditor .dx-texteditor-input,
.dx-data-row {
  font-family: $font-family;
  color: $black;
}

// Font-size for regular text
.dx-datagrid .dx-row > td,
.dx-editor-cell .dx-texteditor .dx-texteditor-input,
.dx-datagrid-headers .dx-datagrid-table .dx-row > td,
.dx-form-group-caption {
  font-size: $font-size;
}

// Small text
.dx-field-item-label-text,
.dip-label {
  font-size: $font-size-small;
}

// bbraun form error styling
.dx-invalid.dx-texteditor {
  &:before {
    border-bottom: 1px solid $invalid-color !important;
    border-top: 1px solid $invalid-color !important;
  }

  &.dx-state-hover:after {
    border-bottom: 1px solid $invalid-color !important;
  }

  &.dx-show-invalid-badge .dx-texteditor-input-container:after {
    display: none;
  }

  .dx-invalid-message > .dx-overlay-content {
    color: $invalid-color;
  }

  // This prevents that the validation message changes its position to the top of the corresponding form field and overlays the label >> See BCORE-437
  .dx-invalid-message .dx-invalid-message-content {
    transform: translate(0px, $dx-input-field-height) !important;
  }
}

.textarea-size-medium {
  .dx-invalid.dx-texteditor .dx-invalid-message .dx-invalid-message-content {
    transform: translate(0px, $dx-textarea-height-medium) !important;
  }
}

.dx-overlay-wrapper.dx-invalid-message {
  font-family: $font-family;
  font-size: $font-size-small;
}

.dx-button-text {
  text-transform: unset;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: bold;
}

.dx-list-next-button {
  .dx-button.dx-button-default {
    background-color: $primary-button-background-color;
  }
}

.bbraun-dx-chart-tooltip {
  .bbraun-dx-chart-tooltip-header {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
  }

  .bbraun-dx-chart-tooltip-body {
    display: inline-block;
    line-height: 1.5;
  }
}

.dx-datagrid-column-chooser .dx-checkbox {
  top: 16px;
}

.dip-dxi-item-readonly
  .dx-texteditor.dx-editor-filled.dx-state-readonly
  .dx-texteditor-input,
.dip-dxi-item-readonly
  .dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover
  .dx-texteditor-input {
  background-color: white;
  color: $black;
}

.dip-dxi-item-readonly .dx-texteditor.dx-state-readonly.dx-state-focused:before,
.dip-dxi-item-readonly .dx-texteditor.dx-state-readonly.dx-state-active:before,
.dip-dxi-item-readonly .dx-texteditor.dx-editor-filled.dx-state-hover:after {
  border-bottom: none;
}

.dip-readonly-checkbox {
  &.dx-state-disabled.dx-widget {
    opacity: 1;
  }

  &.dx-checkbox-checked.dx-state-disabled .dx-checkbox-icon,
  .dx-checkbox-icon {
    border: 2px solid $gray-mid;
    color: $green;
    background-color: transparent;
  }
}

dx-drop-down-button.bbraun-dropdown-button {
  &:not(.icon-only) {
    box-shadow: inset 0 0 0 2px $secondary-button-border-color;
    background-color: $white;
    color: $secondary-button-border-color;

    .dx-button-content > * {
      color: $secondary-button-border-color;
    }

    // hover & disabled
    .dx-buttongroup-item.dx-button.dx-state-hover {
      background-color: $secondary-button-background-color-hover;
    }

    .dx-item-content:hover > .dx-button-content > * {
      color: $white;
    }

    .dx-buttongroup-item.dx-button.dx-state-disabled {
      box-shadow: inset 0 0 0 2px $secondary-button-border-color-disabled;
    }

    .dx-state-disabled .dx-icon,
    .dx-state-disabled .dx-button-text {
      color: $secondary-button-border-color-disabled;
    }

    &.dx-state-disabled.dx-widget,
    .dx-state-disabled .dx-widget,
    .dx-state-disabled .dx-icon,
    .dx-buttongroup-item.dx-button.dx-state-disabled .dx-button-content {
      opacity: 1;
    }

    // general
    .dx-button-has-icon.dx-button-has-text .dx-icon {
      font-size: $icon-button-size;
    }

    &.color-accent {
      box-shadow: none;
      background-color: $action-button-background-color;
      color: $white;

      .dx-button-content > * {
        color: $white;
      }

      // hover & disabled
      .dx-buttongroup-item.dx-button.dx-state-hover {
        background-color: $action-button-background-color-hover;
      }

      .dx-buttongroup-item.dx-button.dx-state-disabled {
        box-shadow: none;
      }

      .dx-buttongroup-item.dx-button.dx-state-disabled {
        background-color: $action-button-background-color-disabled;
      }

      .dx-state-disabled .dx-icon,
      .dx-state-disabled .dx-button-text {
        color: $white;
      }
    }
  }

  &.icon-only {
    .dx-buttongroup-item.dx-button {
      &.dx-state-hover,
      &.dx-state-focused {
        background-color: transparent;
      }
    }

    .dx-button-has-icon {
      min-width: unset;
    }

    &.dx-dropdownbutton:not(.dx-dropdownbutton-has-arrow)
      .dx-button-has-icon:not(.dx-button-has-text)
      .dx-button-content {
      padding: 0;
    }

    .dx-button {
      height: unset;
    }
  }
}

// Set cursor in popup suggestion items of dropdownbutton
.dx-dropdownbutton-popup-wrapper {
  .dx-list .dx-icon {
    color: $black;
  }

  .dx-list-item {
    cursor: pointer;
  }
}

// TODO mrehmer: was implemented to hide bug BCORE-900 but collides with other elements. Needs to be fixed in BCORE-900
.dx-overlay-content.dx-popup-normal.dx-popup-inherit-height.dx-resizable {
  //visibility: hidden;
}

.dx-htmleditor-toolbar-separator {
  height: 1.5rem;
}

.dx-dropdowneditor-overlay.dx-overlay-modal > .dx-overlay-content {
  .dx-popup-title,
  .dx-popup-content,
  .dx-popup-bottom {
    padding-left: $default-padding;
    padding-right: $default-padding;

    &.dx-popup-title .dx-closebutton {
      $dx-cancel-icon-empty-space-offset: -0.5rem;
      margin-right: $dx-cancel-icon-empty-space-offset;
      margin-top: $dx-cancel-icon-empty-space-offset;
    }

    &.dx-popup-bottom.dx-toolbar {
      padding-bottom: $default-padding;

      .dx-toolbar-items-container {
        height: $standard-button-height;

        .dx-popup-done.dx-button {
          background-color: $action-button-background-color;
          color: $action-button-text-color;

          &:hover {
            background-color: $action-button-background-color-hover;
          }
        }

        .dx-popup-cancel.dx-button {
          background-color: $primary-button-background-color;
          color: $primary-button-text-color;
          box-shadow: $primary-button-box-shadow;

          &:hover {
            background-color: $primary-button-background-color-hover;
          }
        }
      }
    }
  }
}
