@import 'styleguide/bbraun/abstracts/variables';

$page-header-text-color: $gray-dark;
$page-header-font-size: $font-size-headline;
$page-header-icon-height: $default-icon-size;

.bav-reporting-page-header {
  font-size: $page-header-font-size;
  font-weight: bold;
  color: $page-header-text-color;
  display: flex;
  align-items: center;

  mat-icon {
    height: $page-header-icon-height;
  }
}
