// TODO: harmonize legacy and new (last three) breakpoints

$breakpoints: (
  small: 576px,
  medium: 768px,
  large: 1024px,
  xlarge: 1400px,
  xxlarge: 1600px,
);

$breakpoint-larger-app-side-padding: 1200px;

@mixin breakpoint($breakpoint, $direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if $direction==max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    }
    @if $direction==min {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    }
  } @else {
    @if $direction==max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else if $direction==min {
      @media (min-width: $breakpoint) {
        @content;
      }
    }
  }
}
